import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import Highlight from '../components/highlight/Highlight';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { getMedia } from '../utils/mediaHelpers';

type Props = {
  node: IStorageHighlights;
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_buttons: {
    primary_button: 'btn-link',
  },
  entity_rows_columns: {
    columns: 3,
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StorageHighlights = function ({ node: highlights }: Props) {
  const settings = Object.assign(DefaultSettings, highlights?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  // @ts-ignore Always a heading element.
  const HighlightHeading: keyof JSX.IntrinsicElements =
    parseInt(HeadingElement.replace(/\D/g, ''), 10) < 5
      ? `h${parseInt(HeadingElement.replace(/\D/g, ''), 10) + 1}`
      : 'div';

  // If all highlights are draft, don't render.
  if (!highlights?.relationships?.highlights || highlights.relationships.highlights.length === 0) {
    return null;
  }

  return (
    <Wrapper data-id={highlights.drupal_id} className="py-3 py-lg-5 px-0" fluid cmsTheme={cmsTheme}>
      <Container
        className={cx(
          highlights?.title || highlights?.body?.processed ? 'pb-3 pb-lg-5' : '',
          'text-center'
        )}
      >
        {highlights?.title && (
          <HeadingElement className={cx(headingStyle)}>{highlights.title}</HeadingElement>
        )}
        {highlights?.body?.processed && (
          <RichText cmsTheme={cmsTheme} className={cx('mt-3')} body={highlights.body.processed} />
        )}
      </Container>
      <Container>
        <Row xs={1} md={3} lg={settings.entity_rows_columns.columns}>
          {highlights?.relationships?.highlights.map((highlight, index) => {
            const media = highlight.relationships?.media;
            return (
              <AnalyticsPoint
                as={Col}
                type="component"
                node={highlight}
                key={index}
                className={`${
                  settings.entity_rows_columns.columns > 4
                    ? 'p-4 p-md-5 p-lg-4'
                    : 'p-4 p-md-5 p-lg-6'
                }`}
              >
                <Highlight
                  drupalId={highlight.drupal_id}
                  media={media && getMedia({ media })}
                  actions={
                    highlight.link && highlight.link.url ? (
                      <ButtonLink
                        to={highlight.link.url}
                        {...highlight.link?.options?.attributes}
                        cmsTheme={cmsTheme}
                        variant={primaryButtonVariation}
                      >
                        {highlight.link.title}
                      </ButtonLink>
                    ) : undefined
                  }
                >
                  {highlight?.title && (
                    <HighlightHeading
                      className={cx(`typography_h${settings.entity_rows_columns.columns}`)}
                    >
                      {highlight.title}
                    </HighlightHeading>
                  )}
                  {highlight?.body?.processed && (
                    <RichText
                      cmsTheme={cmsTheme}
                      className={cx('mt-3')}
                      body={highlight.body.processed}
                    />
                  )}
                </Highlight>
              </AnalyticsPoint>
            );
          })}
        </Row>
      </Container>
    </Wrapper>
  );
};

export default StorageHighlights;
